import http from "../../Store/baseHttp";
import ResponseObject from "../../DataObjects/ResponseObject";
import BaseController from "./BaseController";
import UserObject from "../../DataObjects/UserObject";

class AuditLogService extends BaseController {
  async index({ request, params }) {
    const url = new URL(request.url);
    url.searchParams.append("profile_id", UserObject.getUser().profile_id);
    try {
      let response = await http.get("/user/audit" + url.search);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }


  async post({ request, params }) {
    const url = new URL(request.url);
    url.searchParams.append("profile_id", UserObject.getUser().profile_id);
    console.log(url.searchParams.get('dateTo'))
    url.searchParams.append('date_to', url.searchParams.get('dateTo'))
    url.searchParams.append('date_from', url.searchParams.get('dateFrom'))
    url.searchParams.delete('page_size');
    try {
      let response = await http.get("/user/audit/download" + url.search);
      response = ResponseObject.from(response);
      return response;
    } catch (e) {
      return ResponseObject.from(e?.response);
    }
  }


}

export default AuditLogService;

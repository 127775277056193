import { useState } from "react";
import { BsX } from "react-icons/bs";
import { Outlet, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import Support from "./Support";

const SupportWrapper = () => {

    const location = useLocation();

    return (
        <div className="w-full">
            <PageHeader>Help & Support</PageHeader>
            <div className="flex items-center justify-end">
                <CreateRequestButton />
            </div>
            <div
                className="mt-[32px] mb-[16px] bg-white px-[18.06px] overflow-x-auto rounded-[16px] space-x-[18.06px] flex items-center justify-start">
                <Link to="/dashboard/FAQ"
                    className="border-box focus:outline-none text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
                    data-active={location.pathname === "/dashboard/FAQ"}
                >
                    FAQ
                </Link>
                <Link to="/dashboard/help-and-support"
                    className="border-box focus:outline-none text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
                    data-active={location.pathname === "/dashboard/help-and-support"}
                >
                    Support Requests
                </Link>

            </div>
            <Outlet />
        </div>
    )
}

const CreateRequestButton = () => {
    const [state, setState] = useState(false)
    return (
        <>
            <button onClick={() => setState(v => !v)} className="bg-black text-white px-6 py-3 rounded-[8px] flex items-center justify-center">
                <span>
                    + Log Support Request
                </span>
            </button>
            <div data-active={state} className=" data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70">
                <div className="relative max-w-md md:max-w-4xl w-full text-center bg-white py-8 rounded-[16px]">
                    <button onClick={() => setState(v => !v)} className="absolute -left-3 -top-3 rounded-full bg-white">
                        <BsX className="w-6 h-6" />
                    </button>
                    <Support setState={setState} />
                </div>
            </div>
        </>
    )
}

export default SupportWrapper;
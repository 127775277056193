import PageHeader from "../../../Components/Dashboard/PageHeader";
import Panel from "../../../Components/Dashboard/Panel";
import {Link, Outlet, useFetcher, useNavigate, useSubmit} from "react-router-dom";
import { useLocation } from "react-router-dom";
import DateRange from "../../../Components/Dashboard/DateRange";
import { BsDownload } from "react-icons/bs";
import UserObject from "../../../DataObjects/UserObject";
import { CreateMemberButton } from "./Members";
import Search from "../../../Components/Dashboard/Search";
import {useContext, useEffect} from "react";
import ToastContext from "../../../Routes/ToastContext";

const Settings = () => {
  const location = useLocation();
  const {submit, data} = useFetcher();
  const navigate = useNavigate();
    const { flashToast } = useContext(ToastContext);

  useEffect(()=>{

      flashToast(data?.success, data?.message)
      if(data?.success){
          window.location.href = data?.data
      }

  }, [data]);

  return (
    <div className="w-full">
      <PageHeader>Settings</PageHeader>
      <div id="portal" className="flex items-center justify-end w-full">
        {location.pathname === "/dashboard/settings/members" && (
          <div className="inline-flex items-center justify-end mt-2 space-x-2">
            <Search q="name" />
            <DateRange />
            {UserObject.getUser().isAdmin() && <CreateMemberButton />}
          </div>
        )}
        {location.pathname === "/dashboard/settings/audit-logs" && (
          <div className="inline-flex items-center justify-end mt-2 space-x-2">
            <Search />
            <DateRange />
            <button onClick={()=>{
                submit(null, {
                    action: location.pathname + location.search,
                    method: "post"
                })

            }}  >
              <span className="bg-black whitespace-nowrap text-white p-2  rounded-[8px] inline-block">
                <BsDownload className="w-4 h-4 text-white" />
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="mt-[32px] mb-[16px] bg-white px-[18.06px] overflow-x-auto rounded-[16px] space-x-[18.06px] flex items-center justify-start">
        <Link
          to="/dashboard/settings"
          className="border-box focus:outline-none text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
          data-active={location.pathname === "/dashboard/settings"}
        >
          Profile
        </Link>
        <Link
          to="/dashboard/settings/members"
          className="border-box focus:outline-none text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
          data-active={location.pathname === "/dashboard/settings/members"}
        >
          Members
        </Link>
        <Link
          to="/dashboard/settings/audit-logs"
          className="border-box focus:outline-none whitespace-nowrap text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
          data-active={location.pathname === "/dashboard/settings/audit-logs"}
        >
          Audit Logs
        </Link>
        <Link
          to="/dashboard/settings/kyc-details"
          className="border-box focus:outline-none whitespace-nowrap text-center border-b-[2px] px-[48.5px] py-[20px] border-[#590DA4] font-[700] text-[16px] leading-[24px] data-[active=false]:text-[#64748B] data-[active=false]:border-transparent"
          data-active={location.pathname === "/dashboard/settings/kyc-details"}
        >
          Organization & KYC Details
        </Link>
      </div>
      <Panel>
        <Outlet />
      </Panel>
    </div>
  );
};

export default Settings;

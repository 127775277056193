import { Suspense, useContext, useEffect, useState } from "react";
import { BsThreeDots, BsX } from "react-icons/bs";
import { Await, useFetcher, useLoaderData } from "react-router-dom";
import Panel from "../../../Components/Dashboard/Panel";
import useTitle from "../../../Hooks/useTitle";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import useTable from "../../../Components/Dashboard/Table/useTable";
import PaginateAll from "../../../Components/Utils/PaginateAll";
import ToastContext from "../../../Routes/ToastContext";
import Submit from "../../../Components/Forms/Submit";
import Input from "../../../Components/Forms/Input";
import Switch from "../../../Components/Forms/Switch";
import { Dropdown, Popconfirm } from "antd";
import UserObject from "../../../DataObjects/UserObject";

const Members = () => {
  useTitle("Members");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const [viewId, setViewId] = useState(null);
  const [editId, setEditId] = useState(null);
  const { data, submit } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success, data?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Name</ColumnHeader>
            <ColumnHeader>Email</ColumnHeader>
            <ColumnHeader>Phone Number</ColumnHeader>
            <ColumnHeader>Role</ColumnHeader>
            <ColumnHeader>Status</ColumnHeader>
            {UserObject.getUser().isAdmin() && (
              <ColumnHeader>Action</ColumnHeader>
            )}
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>
                    <span className="whitespace-nowrap">
                      {v?.first_name} {v?.last_name}
                    </span>
                  </Column>
                  <Column>{v.email}</Column>
                  <Column>{v.phone_number}</Column>
                  <Column>
                    <span className="capitalize">{v.role}</span>
                  </Column>
                  <Column>
                    <Status status={v.active_status} />
                  </Column>
                  {UserObject.getUser().isAdmin() && (
                    <Column>
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <button
                                  onClick={() => setViewId(v)}
                                  className="text-sm text-blue-500 focus:outline-none"
                                >
                                  View
                                </button>
                              ),
                            },
                            {
                              key: "2",
                              label: (
                                <button
                                  className="text-sm text-blue-500 focus:outline-none"
                                  onClick={() => setEditId(v)}
                                >
                                  Edit
                                </button>
                              ),
                            },
                            {
                              key: "3",
                              label: (
                                <Popconfirm
                                  title={`${
                                    Number(v.active_status) === 1
                                      ? "Deactivate"
                                      : "Activate"
                                  } Member`}
                                  onConfirm={() => {
                                    submit(
                                      {
                                        profile_id: v.profile_id,
                                        status:
                                          Number(v.active_status) === 1
                                            ? "deactivate"
                                            : "activate",
                                      },
                                      {
                                        action: "dashboard/settings/members",
                                        method: "patch",
                                      }
                                    );
                                  }}
                                  okText={
                                    <span className="text-black">Yes</span>
                                  }
                                  className="text-black"
                                >
                                  {Number(v.active_status) === 1
                                    ? "Deactivate"
                                    : "Activate"}
                                </Popconfirm>
                              ),
                            },
                            {
                              key: "4",
                              label: (
                                <Popconfirm
                                  title={`Delete Transaction`}
                                  onConfirm={() => {
                                    submit(
                                      { profile_id: v.profile_id },
                                      {
                                        action: "dashboard/settings/members",
                                        method: "delete",
                                      }
                                    );
                                  }}
                                  okText={
                                    <span className="text-black">Yes</span>
                                  }
                                  className="text-black"
                                >
                                  Delete
                                </Popconfirm>
                              ),
                            },
                          ],
                        }}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                      >
                        <div className="flex items-center justify-center">
                          <BsThreeDots className="w-4 h-4" />
                        </div>
                      </Dropdown>
                    </Column>
                  )}
                </Row>
              ))
            }
          </Body>
        </Table>
        <MembersEmptyState items={items} />
        <ViewMemberButton view={viewId} />
        <EditMemberButton view={editId} />
      </Panel>
      <PaginateAll items={items} />
    </>
  );
};

const MembersEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div></div>}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data === undefined || data?.data?.data.length <= 0) {
            return (
              <div className="bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center">
                <img src={InvoiceEmpty} alt="Transactions" />
                <p className="mt-5 text-[#64748B]">
                  You don’t have any transaction <br /> at the time
                </p>
                <CreateMemberButton />
              </div>
            );
          }
          return null;
        }}
      </Await>
    </Suspense>
  );
};

export const CreateMemberButton = () => {
  const [state, setState] = useState(false);
  return (
    <>
      <button
        onClick={() => setState((v) => !v)}
        className="bg-black text-white px-6 py-3 rounded-[8px] flex items-center justify-center"
      >
        <span className="whitespace-nowrap">+ Add Member</span>
      </button>
      <div
        data-active={state}
        className=" data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70"
      >
        <div className="relative max-w-sm w-full bg-white py-8 rounded-[16px]">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <MemberModal setState={setState} />
        </div>
      </div>
    </>
  );
};

const MemberModal = ({ setState }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">New Member</h1>
      <div className="mt-10">
        <div className="w-full text-left">
          <div className="px-6 py-4">
            <Form method="post">
              {/* <Input normalize={true} name='first_name' label="first name" error={data?.errors?.first_name} />
                            <Input normalize={true} name='last_name' label="Last name" error={data?.errors?.last_name} /> */}
              <Input
                name="email"
                label="email"
                type="email"
                error={data?.errors?.email}
              />
              {/* <Input name='phone_number' label="phone number" error={data?.errors?.phone_number} /> */}
              <input
                name="onboard_url"
                type="hidden"
                value={window.location.hostname + "/auth/complete/onboard"}
              />
              {/* <Switch label="Status" name='status' /> */}
              <div className="flex items-center justify-end w-full mt-2 space-x-2">
                <button
                  onClick={() => {
                    setState((v) => !v);
                  }}
                  type="reset"
                  className="py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-[#590DA4] border text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900"
                >
                  Cancel
                </button>
                <Submit type="submit" isLoading={state !== "idle"}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const ViewMemberButton = ({ view }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (view) {
      setState(true);
    }
  }, [view]);

  return (
    <>
      <div
        data-active={state}
        className=" data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70"
      >
        <div className="relative max-w-sm w-full bg-white py-8 rounded-[16px]">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <MemberViewModal setState={setState} view={view} />
        </div>
      </div>
    </>
  );
};

const MemberViewModal = ({ setState, view }) => {
  const { data } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">New Member</h1>
      <div className="mt-5">
        <div className="w-full text-left">
          <div className="flex flex-col items-center justify-start w-full px-6 py-4 space-y-4">
            <div className="w-[72px] h-[72px] rounded-full border border-blue-500 bg-gray-500"></div>
            <div className="w-full text-left">
              <h3 className="font-[700] text-[18px] leading-[27px] text-[#4B5768]">
                Name
              </h3>
              <p className="font-[700] text-[18px] leading-[27px] text-[#0D0F11]">
                {view?.first_name} {view?.last_name}
              </p>
            </div>
            <div className="w-full text-left">
              <h3 className="font-[700] text-[18px] leading-[27px] text-[#4B5768]">
                Email
              </h3>
              <p className="font-[700] text-[18px] leading-[27px] text-[#0D0F11]">
                {view?.email}
              </p>
            </div>
            <div className="w-full text-left">
              <h3 className="font-[700] text-[18px] leading-[27px] text-[#4B5768]">
                Phone{" "}
              </h3>
              <p className="font-[700] text-[18px] leading-[27px] text-[#0D0F11]">
                {view?.phone_number}
              </p>
            </div>
            <div className="w-full text-left">
              <Status status={view?.active_status} />
            </div>
            <div className="flex items-center justify-end w-full">
              <button
                onClick={() => {
                  setState(false);
                }}
                className="px-6 py-3 text-sm font-semibold text-black border-2 rounded border-gray-400/50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EditMemberButton = ({ view }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (view) {
      setState(true);
    }
  }, [view]);

  return (
    <>
      <div
        data-active={state}
        className=" data-[active=false]:hidden top-0 left-0 z-[999] flex items-start pt-10 justify-center fixed w-full h-screen overflow-y-auto bg-purple-700/70"
      >
        <div className="relative max-w-sm w-full bg-white py-8 rounded-[16px]">
          <button
            onClick={() => setState((v) => !v)}
            className="absolute bg-white rounded-full -left-3 -top-3"
          >
            <BsX className="w-6 h-6" />
          </button>
          <MemberEditModal setState={setState} view={view} />
        </div>
      </div>
    </>
  );
};

const MemberEditModal = ({ setState, view }) => {
  const { Form, data, state } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success ?? false, data?.message);
    if (data?.success) {
      setState((v) => !v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <h1 className="px-6 text-xl font-semibold text-left">New Member</h1>
      <div className="mt-5">
        <div className="w-full text-left">
          <div className="px-6 py-4">
            <Form method="put">
              <input
                type={"hidden"}
                name="profile_id"
                value={view?.profile_id}
              />
              <Input
                normalize={true}
                name="first_name"
                label="first name"
                defaultValue={view?.first_name}
                error={data?.errors?.first_name}
              />
              <Input
                normalize={true}
                name="last_name"
                label="Last name"
                defaultValue={view?.last_name}
                error={data?.errors?.last_name}
              />
              <Input
                name="email"
                label="email"
                type="email"
                value={view?.email}
                error={data?.errors?.email}
              />
              <Input
                name="phone_number"
                label="phone number"
                value={view?.phone_number}
                error={data?.errors?.phone_number}
              />
              {view?.status && (
                <Switch
                  yes={"activate"}
                  no={"deactivate"}
                  isChecked={view?.active_status === "1"}
                  label="Activate"
                  name="status"
                />
              )}
              <div className="flex items-center justify-end w-full mt-2 space-x-2">
                <button
                  onClick={() => {
                    setState((v) => !v);
                  }}
                  type="reset"
                  className="py-3 w-full px-4 rounded-md tracking-wider text-base min-h-[59px] font-bold border-[#590DA4] border text-[#590DA4] hover:text-white disabled:opacity-50 hover:bg-purple-900"
                >
                  Cancel
                </button>
                <Submit type="submit" isLoading={state !== "idle"}>
                  Submit
                </Submit>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

const Status = ({ status }) => {
  console.log(status);
  const colors = [
    {
      color: "text-yellow-700",
      key: "Pending",
      background: "bg-yellow-200/50",
    },
    { color: "text-green-700", key: "Active", background: "bg-green-200/50" },
    { color: "text-red-700", key: "Disabled", background: "bg-red-200/50" },
  ];

  return (
    <span
      className={`${colors[status]?.color} capitalize ${colors[status]?.background} rounded-full text-xs px-3 py-1 `}
    >
      {colors[status]?.key}
    </span>
  );
};

export default Members;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Await,
  Link,
  useFetcher,
  useLoaderData,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import useTable from "../../../Components/Dashboard/Table/useTable";
import usePagination from "../../../Components/Dashboard/Table/usePagination";
import PageHeader from "../../../Components/Dashboard/PageHeader";
import { BiSearch } from "react-icons/bi";
import Panel from "../../../Components/Dashboard/Panel";
import MoneyFormat from "../../../Components/Utils/MoneyFormat";
import { Suspense, useContext, useEffect, useState } from "react";
import useTitle from "../../../Hooks/useTitle";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import { BsCalendar, BsDownload, BsThreeDots, BsX } from "react-icons/bs";
import { Dropdown } from "antd";
import InvoiceService from "../../../Services/Dashboard/InvoiceService";
import ToastContext from "../../../Routes/ToastContext";

const Invoice = () => {
  useTitle("Invoice");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();
  const { Next, Prev, Button, Pagination, setPage, PageSize, Description } =
    usePagination();
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);

  const [, setSearchParams] = useSearchParams({ search: "" });
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const searchAction = (e) => {
    const s = Object.fromEntries(search);
    setSearchParams({ ...s, search: e.target.value });
  };

  return (
    <div className="w-full">
      <PageHeader>Invoice</PageHeader>
      <div className="flex items-center justify-end mt-5 mb-5 space-x-2">
        <div
          className={`w-full md:max-w-[291.83px]  h-[40px] bg-[#e7eaee] rounded-[8px] flex items-center justify-start`}
        >
          <BiSearch className="w-[15px] h-[15px] flex-none border-none ml-[15px] mr-[6.5px]" />
          <input
            type="text"
            placeholder="search"
            className="flex-1 focus:ring-transparent bg-transparent h-full w-full border-none focus:outline-none focus:border-transparent appearance-none placeholder:text-[#64748b] font-[500] leading-[21px] text-[14px]"
            onChange={searchAction}
          />
        </div>
        <Link
          to="/dashboard/invoice/create"
          className="bg-black text-sm text-white px-6 py-2 whitespace-nowrap rounded-[8px]"
        >
          + New Invoice
        </Link>
      </div>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Receiver Name</ColumnHeader>
            <ColumnHeader>Issue Date</ColumnHeader>
            <ColumnHeader>Due Date</ColumnHeader>
            <ColumnHeader>Amount</ColumnHeader>
            <ColumnHeader>Created By</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>{v.company_name}</Column>
                  <Column>{v.issue_date}</Column>
                  <Column>{v.due_date}</Column>
                  <Column>
                    <p className="text-left">₦{MoneyFormat(v.total)}</p>
                  </Column>
                  <Column>
                    {v.user.first_name} {v.user.last_name}
                  </Column>
                  <Column>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <button
                                className="text-sm text-blue-500"
                                onClick={() => setToken(v)}
                              >
                                View Invoice
                              </button>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <button
                                className="text-sm text-red-500"
                                onClick={() => setId(v.token)}
                              >
                                Send as Email
                              </button>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <div className="flex items-center justify-center">
                        <BsThreeDots className="w-4 h-4" />
                      </div>
                    </Dropdown>
                  </Column>
                </Row>
              ))
            }
          </Body>
        </Table>
        <InvoiceEmptyState items={items} />
      </Panel>
      <div className="flex items-center justify-between mt-2">
        <Description data={items} />
        <PageSize numbers={[5, 10, 20, 50]} />
        <Pagination>
          <Suspense fallback={<p></p>}>
            <Await resolve={items.data} errorElement={<span>Loading</span>}>
              {(data) => {
                return (
                  <>
                    <Prev
                      onClick={() => {
                        setPage(1);
                      }}
                    />
                    {Array(data?.data?.last_page)
                      .fill(5)
                      .map((v, i) => {
                        return (
                          <Button
                            key={i}
                            isActive={data?.data?.current_page === i + 1}
                            onClick={() => {
                              setPage(i + 1);
                            }}
                          >
                            {i + 1}
                          </Button>
                        );
                      })}
                    <Next
                      onClick={() => {
                        setPage(data?.data?.last_page);
                      }}
                    />
                  </>
                );
              }}
            </Await>
          </Suspense>
        </Pagination>
      </div>
      <SendInvoiceViaEmail id={id} setId={setId} />
      {token && <PreviewEmail id={token} setId={setToken} />}
    </div>
  );
};

const PreviewEmail = ({ id = null, setId = null }) => {
  return (
    <div
      className={`fixed z-[999] top-0 left-0 w-full h-full flex-col py-10 overflow-y-scroll items-center bg-purple-700/70 justify-start ${
        id ? "flex" : "hidden"
      }`}
    >
      <div className="relative bg-white min-h-[744.16px] max-w-[569.43px] w-full shadow rounded-[8px] p-10 ">
        <button
          onClick={() => setId(null)}
          className="absolute p-1 bg-white rounded-full -top-2 -left-2"
        >
          <BsX className="w-6 h-6" />
        </button>
        <div className="w-full">
          <div className="flex items-start justify-between w-full mb-16">
            <div className="flex items-start justify-end space-x-3 text-left">
              <div
                className="w-20 h-20 bg-blue-500 bg-center bg-cover rounded-md"
                style={{ backgroundImage: `url(${id?.logo_path})` }}
              ></div>
              <div className="flex flex-col items-start justify-end">
                <h2 className="text-2xl font-semibold">
                  #{id?.invoice_number}
                </h2>
                <div className="flex items-center justify-start space-x-2 text-sm text-gray-400">
                  <BsCalendar className="w-4 h-4" />
                  <p className="">{id?.issue_date}</p>
                </div>
              </div>
            </div>
            <div className="flex items-start justify-end text-right">
              <div className="flex flex-col items-start justify-start">
                <h2>Due</h2>
                <div className="flex items-center justify-start space-x-2 text-sm text-gray-400">
                  <BsCalendar className="w-4 h-4" />
                  <p>{id?.due_date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-between w-full mb-10 text-base font-semibold">
            <div className="flex items-start justify-start text-left">
              <div>
                <span className="text-gray-700">To</span>
                <h3 className="font-bold tracking-wide">{id?.company_name}</h3>
                <p className="text-xs font-semibold ">{id?.company_email}</p>
                <p className="text-gray-400">{id?.company_address}</p>
                <p className="text-gray-400">{id?.company_phone_number}</p>
              </div>
            </div>
            <div className="flex items-start justify-end text-left">
              <div>
                <span className="text-gray-700">FROM</span>
                <h3 className="font-bold tracking-wide">
                  {id?.user?.company_name}
                </h3>
                <p className="text-xs font-semibold ">{id?.user?.email}</p>
                <p className="text-gray-400">
                  {id?.user?.first_name} {id?.user?.last_name}
                </p>
                <p className="text-gray-400">{id?.user?.address}</p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <table className="w-full text-left">
              <thead>
                <tr className="bg-gray-100 rounded-[10px] text-left capitalize">
                  <th className="px-2 py-3 text-sm font-normal">Item</th>
                  <th className="px-2 py-3 text-sm font-normal">Quantity</th>
                  <th className="px-2 py-3 text-sm font-normal">Price</th>
                  <th className="px-2 py-3 text-sm font-normal">total</th>
                </tr>
              </thead>
              <tbody>
                {id &&
                  id.items.map((item, k) => (
                    <tr
                      key={k}
                      className="text-sm font-semibold tracking-wide border-b last:border-b-transparent"
                    >
                      <td className="px-2 py-3">{item?.item}</td>
                      <td className="px-2 py-3">{item?.quantity}</td>
                      <td className="px-2 py-3">{item?.unit_price}</td>
                      <td className="px-2 py-3">₦{MoneyFormat(item?.total)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col items-end justify-start ">
          <div
            className="flex items-center justify-end py-2 space-x-2 border-b"
            style={{ borderColor: id?.color }}
          >
            <div className="flex flex-col items-end justify-start mr-6 text-right">
              <span>Subtotal</span>
              <span>Discount({id.discount_value}%)</span>
              <span>Tax({id.tax_value}%)</span>
            </div>
            <div className="flex flex-col items-end justify-start text-right">
              <span>₦{MoneyFormat(id.subTotal)}</span>
              <span>₦{MoneyFormat(id.discount)}</span>
              <span>₦{MoneyFormat(id.tax)}</span>
            </div>
          </div>
          <div className="flex items-center justify-end py-2 space-x-2">
            <div className="flex flex-col items-end justify-start mr-6 text-right">
              <span>Total</span>
              {/* <span>Balance Due</span> */}
            </div>
            <div className="flex flex-col items-end justify-start text-right">
              <span>₦{MoneyFormat(id.total)}</span>
              {/* <span>₦{}</span> */}
            </div>
          </div>
        </div>
        <div className="font-[400] text-[14px] leading-[21px]">
          <div className="mb-[10px]">
            <h4 className="text-[#590DA4] mb-[5px] font-[700] text-[12px] leading-[18px]">
              {" "}
              Notes
            </h4>
            <p>{id?.comments}</p>
          </div>
          <div>
            {/* <h4 className="text-[#590DA4] mb-[5px] font-[700] text-[12px] leading-[18px]">
              {" "}
              Terms
            </h4> */}
            {/* <p>
              Please Pay withing 30days using the link in your invoice email
            </p> */}
          </div>
        </div>
      </div>
      <div className="bg-white mt-5 max-w-[569.43px] w-full shadow rounded-[8px] p-3 flex items-center justify-end">
        <button
          onClick={() => {
            InvoiceService.downlod(id?.token).then((r) => window.open(r?.data));
          }}
          className="px-6 py-3 bg-purple-700 rounded-[10px] flex items-center justify-center space-x-3 text-white font-semibold text-sm"
        >
          <BsDownload className="w-6 h-6" />
          <span>Download as PDF</span>
        </button>
      </div>
    </div>
  );
};

const SendInvoiceViaEmail = ({ id = null, setId }) => {
  const { Form, state, data } = useFetcher();
  const { flashToast } = useContext(ToastContext);

  useEffect(() => {
    flashToast(data?.success, data?.message);
    if (data?.success) {
      setId(null);
    }
  }, [data, setId]);

  return (
    <Form
      method="post"
      className={`fixed z-[999] top-0 left-0 w-full h-full items-center bg-purple-700/70 justify-center ${
        id ? "flex" : "hidden"
      }`}
    >
      <div className="relative max-w-sm w-full bg-white shadow rounded-[8px] p-10">
        <button
          type="button"
          onClick={() => setId(null)}
          className="absolute p-1 bg-white rounded-full -top-2 -left-2"
        >
          <BsX className="w-6 h-6" />
        </button>
        <h2 className="font-[700] text-[24px] leading-[27.67px] tracking-[2%] mb-[24px]">
          Send Via Email
        </h2>
        <p className="font-[400] text-[18px] leading-[27px]">
          Send Generated Invoice
        </p>
        <Input
          name="company_email"
          type="email"
          multiple
          normalize={true}
          placeholder="Enter your email(s)"
        />
        <input type="hidden" name="invoice_token" defaultValue={id} />
        <p className="mb-3 -mt-5 text-xs text-gray-300 ">
          (Enter emails comma separated)
        </p>
        <Submit isLoading={state !== "idle"}>Send as Email</Submit>
      </div>
    </Form>
  );
};

const InvoiceEmptyState = ({ items }) => (
  <Suspense fallback={<div></div>}>
    <Await resolve={items?.data}>
      {(data) => {
        if (data?.data?.data.length <= 0) {
          return (
            <div className="bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center">
              <img src={InvoiceEmpty} alt="" />
              <p className="mt-5 text-[#64748B]">
                You don’t have any invoice <br /> at the time
              </p>
              <Link to="/dashboard/invoice/create">
                <button className="bg-black text-white px-6 py-3 mt-5 rounded-[8px]">
                  + New Invoice
                </button>
              </Link>
            </div>
          );
        }
      }}
    </Await>
  </Suspense>
);

export default Invoice;

import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import InvoiceEmpty from "../../../Assets/images/InvoiceEmpty.png";
import moment from "moment";
import Panel from "../../../Components/Dashboard/Panel";
import useTitle from "../../../Hooks/useTitle";
import useTable from "../../../Components/Dashboard/Table/useTable";
import PaginateAll from "../../../Components/Utils/PaginateAll";

const AuditLogs = () => {
  useTitle("Audit Logs");
  const { Table, RowHeader, ColumnHeader, Body, Column, Row } = useTable();
  const items = useLoaderData();

  return (
    <>
      <Panel>
        <Table>
          <RowHeader>
            <ColumnHeader>Email Address</ColumnHeader>
            <ColumnHeader>Type</ColumnHeader>
            <ColumnHeader>Action</ColumnHeader>
            <ColumnHeader>Timestamp</ColumnHeader>
          </RowHeader>
          <Body data={items}>
            {(data) =>
              data?.data?.data?.map((v, i) => (
                <Row key={i}>
                  <Column>{v?.user?.email}</Column>
                  <Column>{v.event_type}</Column>
                  <Column>{v.activity}</Column>
                  <Column>{moment(v.created_at).format("llll")}</Column>
                </Row>
              ))
            }
          </Body>
        </Table>
        <AuditLogEmptyState items={items} />
      </Panel>
      <PaginateAll items={items} />
    </>
  );
};

const AuditLogEmptyState = ({ items }) => {
  return (
    <Suspense fallback={<div></div>}>
      <Await resolve={items?.data}>
        {(data) => {
          if (data?.data?.data.length <= 0) {
            return (
              <div className="bg-white rounded-[16px] min-h-[400px] md:min-h-[600px] text-center flex justify-center flex-col items-center">
                <img src={InvoiceEmpty} alt="Transactions" />
                <p className="mt-5 text-[#64748B]">No audit logs found</p>
              </div>
            );
          }

          return null;
        }}
      </Await>
    </Suspense>
  );
};

export default AuditLogs;

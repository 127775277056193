import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import LandingPage from "../Layouts/LandingPage";
import LandIndex from "../../src/Pages/LandingPage/Index";
import AuthLayout from "../Layouts/AuthLayout";
import Login from "../Pages/Auth/Login";
import AboutUs from "../Pages/AboutUs/AboutUs";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import Verification from "../Pages/Auth/Verification";
import ResetPassword from "../Pages/Auth/ResetPassword";
import PasswordReset from "../Pages/Auth/PasswordReset";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../Pages/Terms/Terms";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Dashboard from "../Layouts/Dashboard";
import JoinList from "../Pages/App/JoinList/JoinList";
import {
  ForgotPasswordService,
  LoginService,
  RegisterService,
  ResendPasswordVerificationService,
  ResendVerificationService,
  ResetPasswordService,
  VerifyPasswordService,
  VerifyResetService,
  VerifyService,
} from "../Services/AuthServices";
import Register from "../Pages/Auth/Register";
import PasswordResetVerify from "../Pages/Auth/PasswordResetVerify";
import { JoinListService } from "../Services/MiscellaneousService";
import DashboardIndex from "../Pages/App/Dashboard/DashboardIndex";
import ResendVerification from "../Pages/Auth/ResendVerification";
import Transaction from "../Pages/App/Transaction/Transaction";
import DashboardController from "../Services/Dashboard/DashboardController";
import TransactionHistoryController from "../Services/Dashboard/TransactionHIstoryController";
import DashboardPanel from "../Components/Dashboard/Dashboard";
import TransactionView from "../Pages/App/Transaction/TransactionView";
import InvoiceCreate from "../Pages/App/Invoice/InvoiceCreate";
import Invoice from "../Pages/App/Invoice/Invoice";
import Settings from "../Pages/App/Setting/Settings";
import InvoiceService from "../Services/Dashboard/InvoiceService";
import TransactionCreate from "../Pages/App/Transaction/TransactionCreate";
import AuditLogService from "../Services/Dashboard/AuditLogService";
import Profile from "../Pages/App/Setting/Profile";
import KYCDetails from "../Pages/App/Setting/KYCDetails";
import AuditLogs from "../Pages/App/Setting/AuditLogs";
import Members from "../Pages/App/Setting/Members";
import SupportService from "../Services/Dashboard/SupportService";
import SupportIndex from "../Pages/App/Support/SupportIndex";
import FAQ from "../Pages/App/Support/FAQ";
import SupportWrapper from "../Pages/App/Support/SupportWrapper";
import FAQService from "../Services/Dashboard/FAQService";
import MemberService from "../Services/Dashboard/MemberService";
import ProfileService from "../Services/Dashboard/ProfileService";
import Complete from "../Pages/Auth/Complete";
import OnboardService from "../Services/Dashboard/OnboardService";
import KYCController from "../Services/Dashboard/KYCController";
import CompleteLogin from "../Pages/App/JoinList/CompleteLogin";
import GeneralService from "../Services/Dashboard/GeneralService";
import Error_404 from "../Pages/Errors/Error_404";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <>
          <ScrollRestoration />
          <Outlet />
        </>
      }
         errorElement={<Error_404 />}
    >
      <Route path="/" element={<LandingPage />}>
        <Route index exact element={<LandIndex />} />
        <Route exact path="about-us" element={<AboutUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-and-conditions" element={<Terms />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
      </Route>
      <Route path="/auth" element={<AuthLayout />}>
        <Route exact path="login" element={<Login />} action={LoginService} />
        <Route
          exact
          path="register"
          element={<Register />}
          action={RegisterService}
        />
        <Route
          exact
          path="forgot-password"
          element={<ForgetPassword />}
          action={ForgotPasswordService}
        />
        <Route
          exact
          path="verify"
          element={<Verification />}
          action={VerifyService}
        >
          <Route
            exact
            path="resend"
            element={<ResendVerification />}
            action={ResendVerificationService}
          />
        </Route>
        <Route
          exact
          path="complete/onboard/:id"
          action={OnboardService.apply().action}
          element={<Complete />}
        />
        <Route
          exact
          path="password-reset-verify"
          element={<PasswordResetVerify />}
          action={VerifyPasswordService}
        />
        <Route
          exact
          path="resend-password-verify"
          action={ResendPasswordVerificationService}
        />
        <Route
          exact
          path="reset-password"
          element={<ResetPassword />}
          action={ResetPasswordService}
        />
        <Route
          exact
          path="password-reset"
          element={<PasswordReset />}
          action={VerifyResetService}
        />
      </Route>

      <Route path="/dashboard" element={<AuthLayout />}>
        <Route
          exact
          path="apply"
          element={<JoinList />}
          action={JoinListService}
        />
        <Route
          exact
          path="complete"
          element={<CompleteLogin />}
          action={GeneralService.complete}
        />
      </Route>

      <Route
        path="/dashboard"
        element={<Dashboard />}
        loader={DashboardController.apply().loader}
        action={KYCController.apply().action}
      >
        <Route
          element={<DashboardIndex />}
          loader={TransactionHistoryController.dashboard}
        >
          <Route
            index
            element={<DashboardPanel />}
            loader={DashboardController.apply().loader}
          />
        </Route>
        <Route
          exact
          path="transaction"
          element={<Transaction />}
          loader={TransactionHistoryController.apply().loader}
        />
        <Route
          exact
          path="transaction/create"
          element={<TransactionCreate />}
          action={DashboardController.apply().action}
        />
        <Route
          exact
          path="transaction/:id"
          element={<TransactionView />}
          action={TransactionHistoryController.apply().action}
          loader={TransactionHistoryController.view}
        />
        <Route
          exact
          path="invoice"
          element={<Invoice />}
          action={InvoiceService.email}
          loader={InvoiceService.apply().loader}
        />
        <Route
          exact
          path="invoice/create"
          element={<InvoiceCreate />}
          action={InvoiceService.apply().action}
          loader={InvoiceService.template}
        />
        <Route exact path="settings" element={<Settings />}>
          <Route
            index
            element={<Profile />}
            action={ProfileService.apply().action}
          />
          <Route
            path="members"
            element={<Members />}
            loader={MemberService.apply().loader}
            action={MemberService.apply().action}
          />
          <Route
            path="audit-logs"
            element={<AuditLogs />}
            loader={AuditLogService.apply().loader}
            action={AuditLogService.apply().action}
          />
          <Route
            path="kyc-details"
            element={<KYCDetails />}
            action={KYCController.apply().action}
          />
        </Route>
        <Route
          path="FAQ"
          exact
          element={<SupportWrapper />}
          action={SupportService.apply().action}
        >
          <Route
            exact
            index
            element={<FAQ />}
            loader={FAQService.apply().loader}
          />
        </Route>
        <Route
          exact
          path="help-and-support"
          element={<SupportWrapper />}
          action={SupportService.apply().action}
        >
          <Route
            exact
            index
            element={<SupportIndex />}
            loader={SupportService.apply().loader}
            action={SupportService.apply().action}
          />
        </Route>
      </Route>
    </Route>
  )
);

const Index = () => {
  return <RouterProvider router={router} />;
};

export default Index;

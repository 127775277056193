import Logo from "../../../Assets/logo/Logo.png";
import Input from "../../../Components/Forms/Input";
import Submit from "../../../Components/Forms/Submit";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../../../Routes/AuthContext";
import ProtectedRoute from "../../../Routes/ProtectedRoute";

const JoinList = () => {
  const { data, state, Form, formAction } = useFetcher();
  const { getUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.success) {
      if (data?.data?.on_wait_list === "1") {
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  return (
    <ProtectedRoute>
      <Link to="/">
        <img
          src={Logo}
          style={{ height: "48px", marginTop: "50px" }}
          alt="Fedhagap Log"
          className="block lg:hidden"
        />
      </Link>
      <div className="mt-40 md:mt-48">
        <p style={styles.para}>
          Welcome to FedhaGap{" "}
          <strong>
            {getUser()?.first_name} {getUser()?.last_name}!
          </strong>{" "}
        </p>
        <p style={styles.para} className="mt-10">
          Join thousands of business owners to be considered for our invoice
          discount solution.
        </p>

        <Form action={formAction} method="post" className="mt-10">
          <Input
            label=""
            require={"true"}
            name="phone_number"
            placeholder="Enter your phone number"
            type="number"
            error={data?.errors?.phone_number}
          />
          <Submit isLoading={state !== "idle"}>
            {/* Join Waitlist for Invoice Discounting */}
            Proceed
          </Submit>
        </Form>
      </div>
    </ProtectedRoute>
  );
};

const styles = {
  para: {
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "36px",
    color: "#4B5768",
  },
};

export default JoinList;

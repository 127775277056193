import {Suspense} from "react";
import {Await} from "react-router-dom";

export const Table = ({children}) => {
    return (
        <div>
            <table className="w-full table-auto">
                {children}
            </table>
        </div>
    )
}

export const ColumnHeader = ({children, className}) => {
    return (
        <th className={`h-[54px] leading-4 whitespace-nowrapXXXXX px-[25.5px] py-[17.5px] ${className}`}>
            {children}
        </th>
    )
}

export const RowHeader = ({children}) => {
    return (
        <thead>
        <tr className="bg-[#f7f8f9] text-left rounded-[8px] text-[#0d0f11] text-[14px] font-[700] leading-[14px]">
            {children}
        </tr>
        </thead>
    )
}

export const Column = ({children}) => {
    return (
        <td className="h-[56px] font-[500] px-[25.5px] py-[17.5px]">
            {children}
        </td>
    )
}

export const Row = ({children}) => {
    return (
        <tr className="text-left whitespce-nowrap rounded-[8px] text-[#323a46] text-[14px] font-[700] leading-[21px]">
            {children}
        </tr>
    )
}

export const Fallback = () => {

    return (
        <>
            {Array(5).fill(5).map((v,i)=>(
                <tr key={i} className="blur-lg animate-pulse text-left rounded-[8px] text-[#323a46] text-[14px] font-[700] leading-[21px]">
                    <th className="h-[56px] appearance-none font-[500] px-[25.5px] py-[17.5px]"></th>
                </tr>
            ))}
        </>
    )
}

export const Body = ({data, children}) => {
    return (
        <tbody className="divide-y-[1.5px]">
        <Suspense fallback={<Fallback />}>
            <Await
                resolve={data?.data}
                errorElement={<tr><td>Error loading package location</td></tr>}
            >
                {(data)=>{
                    return children(data)
                }}
            </Await>
        </Suspense>
        </tbody>
    )
}


const useTable = () => {
    return {Table, RowHeader, ColumnHeader, Row, Column, Body}
}


export default useTable;
import { Suspense, useRef } from "react";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import useTitle from "../../../Hooks/useTitle";
import { Await, useLoaderData } from "react-router-dom";
import useClickOutside from "../../../Hooks/useClickOutside";

const FAQ = () => {

  useTitle("Frequently Asked Questions");
  const items = useLoaderData();
  return (
    <div>
      <h2 className="font-[700] mt-[43px] mb-[33px] text-[30px] leading-[34.59px] tracking-[-2%]">
        Hi! How can we help you?
      </h2>
      <div className="flex flex-col items-start justify-start w-full space-y-3">
        <Suspense fallback={<p></p>}>
          <Await resolve={items.data} errorElement={<span>Loading</span>}>
            {(data) => {
              const faqs = groupBy(data?.data?.data, "category");
              return Object.keys(faqs).map((faq, i) => (
                <div key={i} className="w-full">
                  <h3 className="font-[700] text-[25px] my-[32px] leading-[24px] text-[#64748B]">
                    {faq}
                  </h3>
                  <div className="flex flex-col items-start justify-start w-full space-y-3">
                    {faqs[faq].map((item, i) => (
                      <Item key={i} data={item} />
                    ))}
                  </div>
                </div>
              ));
            }}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const Item = ({ data }) => {
  const detailRef = useRef();

  const handleOutsideClick = () => {
    detailRef.current.removeAttribute("open");
  };

  useClickOutside(detailRef, handleOutsideClick);

  return (
    <details
      ref={detailRef}
      className="py-[20px] px-[30px] bg-white w-full rounded-[10px] group border-b-[1px] border-[#590DA4]"
    >
      <summary className="flex items-center font-[700] text-[20px] leading-3 justify-between w-full">
        <p className="w-full pr-4">{data?.question}</p>
        <button className="flex-1 border-none focus:outline-none">
          <BsPlus className="w-8 h-8 group-open:hidden" />
          <BiMinus className="hidden w-8 h-8 group-open:inline-block" />
        </button>
      </summary>
      <p className="mt-5">{data?.answer}</p>
    </details>
  );
};

export default FAQ;
